import type { AppProps } from 'next/app';
import PageViewAnalytics from '../../../analytics/PageViewAnalytics';
import { Page } from '../../types';
import ClientSideOnly from '../../ClientSideOnly';
import MinimalLayout from './MinimalLayout';

type AppPropsWithLayout = AppProps & {
  Component: Page;
};

export default function MinimalApp({ Component, pageProps }: AppPropsWithLayout) {
  return (
    <ClientSideOnly>
      <PageViewAnalytics />
      <MinimalLayout>
        <Component {...pageProps} />
      </MinimalLayout>
    </ClientSideOnly>
  );
}
